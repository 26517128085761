
import Vue from 'vue'
import { mapActions } from 'vuex'
export default Vue.extend({
  name: 'Header',
  data () {
    return {
      isMenuOpen: false,
      popupError: false,
      error: {},
      $gtm: null,
      resizeTimeout: null
    }
  },
  computed: {
    getUserName() {
      const capitalize = (str) => {
        if (!str) { return '' }
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      }
      const firstName = capitalize(this.$auth.user.first_name)
      const lastName = capitalize(this.$auth.user.last_name)

      return `${firstName} ${lastName}`
    },
    profileLink () {
      let width = 768
      if (process.browser) {
        width = window.innerWidth
      }
      if (width < 768) {
        return 'user-settings'
      } else {
        return 'user-settings-profile'
      }
    }
  },
  beforeCreate() {
    if (this.$auth.loggedIn) {
      this.$i18n.setLocale(this.$auth.user.locale)
    }
  },
  mounted() {
    this.checkIfMobile()
    window.addEventListener('resize', this.debouncedCheckIfMobile)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedCheckIfMobile)
  },
  methods: {
    ...mapActions({
      setIsMobile: 'site/setIsMobile'
    }),
    debouncedCheckIfMobile() {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.checkIfMobile()
      }, 500)
    },
    checkIfMobile() {
      if (typeof window !== 'undefined' || typeof navigator !== 'undefined') {
        const userAgent = navigator?.userAgent || ''
        if (userAgent) {
          const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent?.toLowerCase())
          const isSmallScreen = typeof window !== 'undefined' && window.innerWidth < 768
          this.setIsMobile(isMobileDevice || isSmallScreen)
          if (document && document.documentElement) {
            document.documentElement.classList.toggle('small-screen', isSmallScreen || isMobileDevice)
          }
        }
      }
    },
    goToPersonalArea() {
      this.track()
      this.trackMenu('Username', 'text')
      const width = window?.innerWidth
      // Verifica se `navigator` è disponibile e usa il fallback `""` se non lo è
      const userAgent = typeof navigator !== 'undefined' ? navigator?.userAgent?.toLowerCase() : ''
      const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)
      // Imposta il percorso in base al tipo di dispositivo
      const path = isMobile || (width < 768) ? 'user-settings' : 'user-settings-profile'
      this.$router.push(this.localePath({ name: path, params: { locale: this.$i18n.locale } }))
    },
    redirectToPlatform() {
      window.open('https://business.cving.com/per-le-aziende', '_blank')
    },
    trackMenu (menu, type) {
      this.$gtm.push({
        event: 'click_menu',
        event_data: {
          menu_location: '',
          menu_type: type,
          menu_text: menu,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    },
    track () {
      this.$gtm.push({
        event: 'click_profile',
        event_data: {
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})
