
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'CVing',
  head () {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },

  computed: {
    ...mapGetters({ isMobile: 'site/isMobile' })
  },
  watch: {
    $route() {
      this.$nuxt.$loading?.start()
      if (this.$auth.loggedIn) {
        this.$axios.get('/proxyapi/auth/get-csrf-token')
        this.$axios.get('/proxystorage/auth/get-csrf-token')
      }

      // Aspetta 250ms prima di terminare il caricamento
      setTimeout(() => {
        this.$nuxt.$loading?.finish()
      }, 250)
    }
  },
  mounted () {
    if (this.$auth.loggedIn) {
      this.$axios.get('/proxyapi/auth/get-csrf-token')
      this.$axios.get('/proxystorage/auth/get-csrf-token')
    }
  }
})
