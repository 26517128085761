
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentVal: 0
    }
  },
  computed: {
    ...mapGetters({ isMobile: 'site/isMobile' }),
    isDarkTheme() {
      if (this.$colorMode.preference === 'system' && document && document.documentElement) {
        return document.documentElement.classList.contains('dark')
      }
      return this.$colorMode.preference === 'dark'
    },
    getName() {
      const firstName = this.$auth?.user?.first_name
      const lastName = this.$auth?.user?.last_name
      if (!firstName || !lastName) {
        return
      }
      return (
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase() +
        ' ' +
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase()
      )
    },
    menuOtherItems() {
      return [
        {
          to: 'https://business.cving.com/per-le-aziende',
          icon: 'pi pi-building',
          text: this.$t('menu.forCompanies')
        },
        {
          to: 'https://media.cving.com/',
          icon: 'pi pi-comments',
          text: this.$t('menu.blog')
        },
        {
          to: 'https://support.cving.com/hc/it',
          icon: 'pi pi-info-circle',
          text: this.$t('menu.assistence'),
          condition: this.$config.p2p
        }
      ]
    },
    menuItems() {
      return [
        {
          to: this.localePath('/'),
          routeName: 'index',
          icon: 'pi pi-home',
          text: this.$t('menu.home')
        },
        {
          to: this.localePath({
            name: 'radar',
            query: {
              p: '1',
              order: '1',
              ea: '0',
              verified: 'true',
              orp: 'false'
            }
          }),
          routeName: 'radar',
          icon: 'pi pi-search',
          text: this.$t('menu.radar')
        },
        {
          to: this.localePath('user-settings-my-cv'),
          routeName: 'user-settings-my-cv',
          icon: 'pi pi-upload',
          text: this.$t('menu.uploadCV'),
          condition: this.$auth?.user?.loggedIn ? this.$auth?.user?.loggedIn : false
        },
        {
          to: 'https://media.cving.com/openreferralprogram',
          external: true,
          icon: 'pi pi-money-bill',
          text: this.$t('menu.suggestJob')
        }
      ]
    },
    textLinks() {
      return [
        {
          name: 'Privacy',
          link: 'https://media.cving.com/privacy/'
        },
        {
          name: this.$t('footer.termsAndConditions').toString(),
          link: 'https://media.cving.com/terms-conditions/'
        },
        {
          name: this.$t('footer.transparency').toString(),
          link: 'https://media.cving.com/trasparenza/'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      setCity: 'radar/setCity',
      setSearchTitle: 'radar/setSearchTitle'
    }),
    changeTheme() {
      this.$colorMode.preference === 'light' ? this.$colorMode.preference = 'dark' : this.$colorMode.preference = 'light'
    },
    goToPersonalArea() {
      this.trackMenu('Username', 'text')
      const path = this.isMobile ? 'user-settings' : 'user-settings-profile'
      console.log(this.$i18n.locale)
      this.$router.push(this.localePath({ name: path, params: { locale: this.$i18n.locale } }))
    },
    openLink(text, link) {
      this.trackMenu(text)
      this.$emit('closeMenu')
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    isActive(routeName) {
      console.log(this.$route.name)
      console.log(routeName)
      return this.$route.name ? this.$route.name.startsWith(routeName) : false
    },
    trackMenu(menu) {
      this.setCity('')
      this.setSearchTitle('')
      this.$gtm.push({
        event: 'click_menu',
        event_data: {
          menu_location: '',
          menu_type: 'button',
          menu_text: menu,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document.title : 'CVing'
        }
      })
      this.$emit('closeMenu')
    }
  }
}
