function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export default ({ $axios, app, $sentry }) => {
  $axios.onRequest(async (config) => {
    console.log('AXIOS REQUEST')
    console.log(config.method + config.url)
    if (!process.server) {
      if (config.url.includes('proxysbapi/auth') && config.method !== 'get' && !getCookie('XSRF-TOKEN')) {
        await $axios.get('/proxysbapi/auth/xsrf-token')
        console.log('v1/auth/xsrf-token')
        console.log(config.method)
      }
      if (config.url.includes('proxyapi/auth') && config.method !== 'get') {
        config.headers.common['API-X-XSRF-TOKEN'] = getCookie('API-XSRF-TOKEN')
      }
      if (config.url.includes('proxystorage/auth') && config.method !== 'get') {
        config.headers.common['STORAGE-X-XSRF-TOKEN'] = getCookie('STORAGE-XSRF-TOKEN')
      }
      config.headers.common['Accept-Language'] = app.i18n.locale
    }

    return config
  })
  $axios.onError((error) => {
    // console.log('ERRORE AXIOSS')
    // console.log(error)
    // console.log(app.$auth)
    // app.$auth.fetchUser()
    if (app.$auth.loggedIn) {
      $sentry.setTag('email', app.$auth.user.email)
    }
    $sentry.captureException(error)
  })
}
