var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout"
  }, [_c('Header'), _vm._v(" "), _c('div', {
    class: _vm.isMobile ? 'main-container-scroll' : 'radar-scroll-fixed-footer',
    attrs: {
      "id": "scroll"
    }
  }, [_c('Nuxt'), _vm._v(" "), _vm.isMobile ? _c('Footer', {
    staticClass: "isMobile"
  }) : _vm._e()], 1), _vm._v(" "), !_vm.isMobile ? _c('Footer', {
    staticClass: "isDesktop"
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }