export default {
  SET_COUNT: (state, payload) => {
    state.count = payload
  },

  SET_IS_FIRST_LOGIN: (state, payload) => {
    state.isFirstLogin = payload
  },

  SET_APPLICATIONS_INCOMPLETE: (state, payload) => {
    state.countApplicationsInomplete = payload
  },

  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload
  },

  SET_PERSONALITY_TEST_NOTIFICATION: (state, payload) => {
    state.personalityNotification = payload
  },

  SET_SUGGESTED_NOTIFICATIONS: (state, payload) => {
    state.suggestedNotifications = payload
  },

  SET_REDEEM_TRANSACTIONS: (state, payload) => {
    state.redeemTransactions = payload
  },

  SET_USER_REDEEM: (state, payload) => {
    state.userRedeem = payload
  }

}
