
import Vue from 'vue'

export default Vue.extend({
  name: 'CVing',
  middleware: 'firstlogin',
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
  mounted() {
    if (this.$auth.loggedIn) {
      this.$axios.get('/proxyapi/auth/get-csrf-token')
      this.$axios.get('/proxystorage/auth/get-csrf-token')
    }
  }
})
