
import Vue from 'vue'

export default Vue.extend({
  name: 'Footer',
  data() {
    return {
      textLinks: [
        { name: 'Privacy', link: 'https://media.cving.com/privacy/' },
        { name: this.$t('footer.termsAndConditions').toString(), link: 'https://media.cving.com/terms-conditions/' },
        { name: this.$t('footer.transparency').toString(), link: 'https://media.cving.com/trasparenza/' },
        { name: this.$t('footer.cookiePolicy').toString(), link: 'https://media.cving.com/cookie-declaration/' }

      ],
      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    handleLinkClick(link: { link?: string }) {
      if (link.link) {
        const newWindow = window.open(link.link, '_blank')
        if (newWindow) {
          newWindow.opener = null
          newWindow.focus()
        }
      }
    }
    /*     toggleCookies() {
      document.body.classList.toggle('show-cookies')
    } */
  }
})
