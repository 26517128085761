export default () => ({
  count: 0,
  isFirstLogin: false,
  countApplicationsInomplete: 0,
  notifications: [],
  personalityNotification: false,
  suggestedNotifications: 0,
  redeemTransactions: [],
  userRedeem: 0
})
